<template>
  <div class="container">
    <div class="bg-white p-2 px-4 radius-5 my-2" v-for="(items, index) in data" :key="index">
      <router-link class="text-decoration-none" :to="'/jobs/info/' + items.job.id">
        <div class="row border-bottom">
          <div class="col px-0 align-self-start">{{ items.job.name }}</div>
          <div class="col px-0 text-r">
            <span style="color: red">{{ SALARYINDEX[items.job.salarys] }}</span>元
          </div>
        </div>
      </router-link>
      <div class="row">
        <div class="col-8 mt-1 p-0">
          <p class="fs-8 m-0 text-muted">{{ JOBS_SCHOOLING[items.job.schooling] }} | 16-50岁</p>
          <p class="fs-8 m-0 text-truncate">
            <span
              class="bg-97 mx-1 text-muted"
              v-for="(val, index) in items.job.tags"
              :key="index"
            >{{ WELFARE[val] }}</span>
          </p>
        </div>

        <div class="col-4 p-0 pt-1 text-r">
          <p class="fs-8 m-0 text-muted">{{ items.created_time }}</p>
        </div>
      </div>
      <div>
        <p class="fs-8 m-0 text-muted">发布时间: {{ items.job.created_time }}</p>
      </div>

      <div class="row my-2">
        <div class="col-1 p-0">
          <img :src="items.company.logo" width="35" />
        </div>

        <div class="col">
          <p class="m-0 fs-8">{{ items.company.name }}</p>
          <p
            class="m-0 fs-7 text-muted"
          >{{ NATURE[items.company.nature] }} | {{ COMPANY_TYPE[items.company.type] }} | {{ items.company.scale }}人</p>
        </div>
        <div class="col-3 p-0 fs-8 text-r pt-3 pr-1">联系客服</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Axios from 'axios'
import { APPLY_STATUS, SALARYINDEX, JOBS_SCHOOLING, AGEINDEX, WELFARE, NATURE, COMPANY_TYPE } from '@/lib/data'
const data = ref([])
const a = 2
for (let i = 0; i < a; i++) {
  data.value.push(
    {
      id: 100, //ID
      created_time: '2022-02-30 15:30', //收藏时间
      job: {
        id: 1001, //职位ID Number
        name: '人事经理', // 职位名称 Strying
        schooling: 1, //学历要求 Number <20
        salarys: 2, //综合薪资 Number <30
        ages: 2, //年龄要求 Number <30
        tags: [0, 3, 4], //职位优势  Array
        created_time: '2022-02-02 23:00:00', //面试时间 Strying
      },
      company: {
        id: 100, //企业id number
        name: '一二一通信（苏州）有限公司', //企业名称 strying
        type: 1, //类型  生产加工 Number
        nature: 2, //性质 国企 number
        scale: 10000, //规模 10000人以上 number
        logo: '/imgs/h03.jpg' //企业Logo strying
      }
    }
  )
}

</script>

<style>
</style>